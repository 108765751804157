<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="180px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="所属区域：" prop="address">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.address }}</p>
                <el-input class="my-input-btn my-input-btn1" v-else :disabled="true" placeholder="请选择" suffix-icon="el-icon-arrow-down" @click.native="chooseType('getpickarea')" v-model="ruleForm.address"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="主体类别：" prop="bodyType">
                <el-radio-group v-model="ruleForm.bodyType" @change="change1" :disabled="editType == 'look'">
                  <el-radio
                    v-for="(item, index) in selectObj.bodyType"
                    :key="index"
                    :label="item.value"> {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="rules.productionBodyType[0].required">
            <el-col :span="24">
              <el-form-item label="生产主体类型：" prop="productionBodyType">
                <el-radio-group v-model="ruleForm.productionBodyType" @change="change2" :disabled="editType == 'look'">
                  <el-radio
                    v-for="(item, index) in selectObj.productionBodyType"
                    :key="index"
                    :label="item.value"> {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="rules.retail[0].required">
            <el-col :span="24">
              <el-form-item label="是否散户：" prop="retail">
                <el-radio-group v-model="ruleForm.retail" @change="initRequired" :disabled="editType == 'look'">
                  <el-radio
                    v-for="(item, index) in selectObj.retail"
                    :key="index"
                    :label="item.value"> {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="rules.businessBodyType[0].required && tempType == '01'">
            <el-col :span="24">
              <el-form-item label="经营主体类型：" prop="businessBodyType">
                <el-radio-group v-model="ruleForm.businessBodyType" :disabled="editType == 'look'">
                  <el-radio
                    v-for="(item, index) in selectObj.businessBodyType"
                    :key="index"
                    :label="item.value"> {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="rules.businessBodyType[0].required && tempType == '02'">
            <el-col :span="24">
              <el-form-item label="经营主体类型：" prop="businessBodyType">
                <el-radio-group v-model="ruleForm.businessBodyType" @change="change3" :disabled="editType == 'look'">
                  <el-radio
                    v-for="(item, index) in selectObj.businessBodyType"
                    :key="index"
                    :label="item.value"> {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.retail != '01'">
            <el-col :span="24">
              <el-form-item label="主体名称：" prop="bodyName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyName }}</p>
                <el-input clearable v-else v-model="ruleForm.bodyName" :placeholder="ruleForm.retail == '01' ? '请输入主体名称' : '请输入企业名称'" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="ruleForm.retail == '01' ? '姓名：' : '法人：'" prop="representativeName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.representativeName }}</p>
                <el-input clearable v-else v-model="ruleForm.representativeName" :placeholder="ruleForm.retail == '01' ? '请输入姓名' : '请输入法人姓名'" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div :class="ruleForm.retail == '01' ? 'cont-source' : ''">
            <p class="cont-source-title" v-if="ruleForm.retail == '01'"><span class="tips-red">*</span>关键信息</p>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="ruleForm.retail == '01' ? '身份证号：' : '社会信用代码：'" prop="creditCode">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.creditCode }}</p>
                  <el-input clearable v-else v-model="ruleForm.creditCode" :placeholder="ruleForm.retail == '01' ? '请输入身份证号' : '请输入社会信用代码'" maxlength=18 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="手机号码：" prop="mobilePhoneNumber">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.mobilePhoneNumber }}</p>
                  <el-input clearable v-else v-model="ruleForm.mobilePhoneNumber" placeholder="请输入手机号码" maxlength=14 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="社区及门牌地址：" prop="doorNumber">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.doorNumber }}</p>
                <el-input clearable v-else v-model="ruleForm.doorNumber" @change="inputChange" placeholder="请输入社区及门牌地址" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="经营地址：" prop="businessAddress">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.businessAddress }}</p>
                <el-input clearable v-else v-model="ruleForm.businessAddress" placeholder="自动生成" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="联系人：" prop="linkman">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.linkman }}</p>
                <el-input clearable v-else v-model="ruleForm.linkman" placeholder="请输入联系人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="联系电话：" prop="linkmanPhoneNumber">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.linkmanPhoneNumber }}</p>
                <el-input clearable v-else v-model="ruleForm.linkmanPhoneNumber" placeholder="请输入联系电话" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pick-area ref="getpickarea" @getpickarea="getpickarea"></pick-area>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pickArea from '@/views/common/pickArea.vue'
let _t = null
export default {
  name: "tempEdit",
  components: {
    pickArea
  },
  props:{
    tempType: {
      type: String,
      default: '01'
    }
  },
  data() {
    return {   
      rules: {
        retail: [{ required: true,  trigger: "change", message: '请选择是否散户'}],
        doorNumber: [{ required: false,  trigger: "change", message: '请输入社区及门牌地址'}],
        address: [{ required: true,  trigger: "change", message: '请选择所属区域'}],
        bodyType: [{ required: true,  trigger: "change", message: '请选择主体类别'}],
        productionBodyType: [{ required: true,  trigger: "change", message: '请选择生产主体类型'}],
        businessBodyType: [{ required: true,  trigger: "change", message: '请选择经营主体类型'}],
        bodyName: [{ required: true,  trigger: "change", message: '请输入主体名称'}],
        representativeName: [{ required: true,  trigger: "change", message: '请输入'}],
        // creditCode: [{ required: true,  trigger: "change", message: '请输入社会信用代码'}],
        mobilePhoneNumber: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        creditCode: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入"))
            }else{
              if(!rule.required && !val) callback()

              let crecode = _t.ruleForm.creditCode || '',
                len = crecode?.length
              if(_t.ruleForm.pro1 == '00'){
                if(!(len == 15 || len == 18)){
                  callback(new Error("请输入正确的统一社会信用代码或身份证"))
                }
              }else{
                if(_t.rules.bodyName[0].required){
                  if(!(len == 15 || len == 18)){
                    callback(new Error("请输入正确的统一社会信用代码"))
                  }
                }else{
                  if(len != 18){
                    callback(new Error("请输入正确的身份证号码"))
                  }
                }
              }
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      initForm: {
        bodyId: '',
        bodyType: '',
        productionBodyType: '',
        businessBodyType: '',
        bodyName: '',
        representativeName: '',
        creditCode: '',
        mobilePhoneNumber: '',
        businessAddress: '',
        linkman: '',
        linkmanPhoneNumber: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        county: '',
        countyCode: '',
        town: '',
        townCode: '',
        village: '',
        villageCode: '',
        address: '',
        retail: '',
        status: '04'
      },
      selectObj: {
        bodyType: [],
        productionBodyType: [],
        retail: CONSTPARAMS.retail,
        businessBodyType: [],
      },
      defaultAddress: [],
    };
  },
  created() {
	},
  mounted() {
    _t = this
    if(this.tempType == '01'){
      this.selectObj.bodyType = CONSTPARAMS.bodyType
      this.selectObj.productionBodyType = CONSTPARAMS.productionBodyType
      this.selectObj.businessBodyType = CONSTPARAMS.businessBodyType
    }else{
      this.selectObj.bodyType = CONSTPARAMS.bodyType2
      this.selectObj.businessBodyType = CONSTPARAMS.productionBodyType2
    }
  },
  methods: {
    chooseType(name){
      this.$refs[name].initData(utils.getAddressByObj(this.ruleForm) || [])
    },
    change1(val){
      if(val == '11'){
        this.ruleForm.businessBodyType = ''
        this.ruleForm.retail = '02'
      }else if(val == '12'){
        this.ruleForm.productionBodyType = ''
        this.ruleForm.retail = ''
      }else if(val == '13'){
        this.ruleForm.businessBodyType = ''
        this.ruleForm.productionBodyType = ''
        this.ruleForm.retail = '02'
      }
      this.initRequired()
    },
    change2(val){
      if(val == '05'){
        this.ruleForm.retail = '01'
      }else{
        this.ruleForm.retail = '02'
      }
      this.initRequired()
    },
    change3(val){
      if(val == '04'){
        this.ruleForm.retail = '01'
      }else{
        this.ruleForm.retail = '02'
      }
      this.initRequired()
    },
    initRequired(){
      let _this = this,
        contObj = _this.ruleForm,
        bodyType = contObj.bodyType,
        retail = contObj.retail
      if(bodyType == '11'){
        this.rules.productionBodyType[0].required = true
        this.rules.businessBodyType[0].required = false
        this.rules.retail[0].required = false
      }else if(bodyType == '12'){
        this.rules.productionBodyType[0].required = false
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = true
      }else if(bodyType == '13'){
        this.rules.productionBodyType[0].required = true
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = false
      }else if(bodyType == '41'){
        this.rules.productionBodyType[0].required = false
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = false
      }else{
        this.rules.productionBodyType[0].required = true
        this.rules.businessBodyType[0].required = true
        this.rules.retail[0].required = true
      }
      if(retail == '01'){
        this.rules.bodyName[0].required = false
        this.rules.creditCode[0].required = false
        this.rules.mobilePhoneNumber[0].required = false
      }else{
        this.rules.bodyName[0].required = true
        this.rules.creditCode[0].required = true
        this.rules.mobilePhoneNumber[0].required = true
      }
    },
    inputChange(val){
      this.ruleForm.businessAddress = (this.ruleForm.address || '') + (val || '')
    },
    getpickarea(data){
      let address = ''
      data.forEach(cur => {
        if(cur.areaCode){
          address += cur.areaName
        }
      })
      this.ruleForm.province = data[0] && data[0].areaName || ''
      this.ruleForm.provinceCode = data[0] && data[0].areaCode || ''
      this.ruleForm.city = data[1] && data[1].areaName || ''
      this.ruleForm.cityCode = data[1] && data[1].areaCode || ''
      this.ruleForm.county = data[2] && data[2].areaName || ''
      this.ruleForm.countyCode = data[2] && data[2].areaCode || ''
      this.ruleForm.town = data[3] && data[3].areaName || ''
      this.ruleForm.townCode = data[3] && data[3].areaCode || ''
      this.ruleForm.village = data[4] && data[4].areaName || ''
      this.ruleForm.villageCode = data[4] && data[4].areaCode || ''
      this.ruleForm.address = address || ''
      this.ruleForm.businessAddress = address + (this.ruleForm.doorNumber || '')
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId()
        let obj = {
          ...this.initForm,
          ...transobj,
          bodyId: id,
          bodyType: this.tempType == '01' ? '' :  '41',
          retail: this.tempType == '01' ? '' :  '02',
          temporaryCreateUser: utils.getUserName()
        }
        this.ruleForm = obj
        this.defaultAddress = []
      }else{
        this.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      this.initRequired()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
      this.defaultAddress = utils.getAddressByObj(this.ruleForm)
    },
    initLook(){
      this.initEdit()
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let contObj = _this.ruleForm
          if(contObj.retail == '01' || (contObj.bodyType == '41' && contObj.businessBodyType == '04')){
            let creditCode = contObj.creditCode,
            mobilePhoneNumber = contObj.mobilePhoneNumber
            if(!creditCode && !mobilePhoneNumber){
              _this.message('身份证号码和手机号不能同时为空！', "warning")
              return
            }
          }
          if(contObj.retail == '01'){
            contObj.bodyName = contObj.representativeName
          }
          let ways = editType == 'add' ? 'commonbodysave' : 'commonbodyupdate',
            target = {
            ...contObj
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.useId = newId
              }else{
                _this.closeModel()
                if(editType == 'add'){
                  _this.$emit("addTemp", target)
                }else{
                  _this.$emit("getData", editType == 'add' ? true : false)
                }
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .cont-source{
    border: 1px dashed #c9c3c3;
    padding: 10px;
    margin-bottom: 20px;
  }
  .cont-source-title{
    margin-left: 90px;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 1px;
  }
</style>


