<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    center
  >
    <div>
      <div class="title-search my-table-list">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="名称:">
              <el-input size="small" v-model="searchForm.bodyName" placeholder="请输入名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="统一信用代码或身份证:">
              <el-input size="small" v-model="searchForm.creditCode" placeholder="请输入" clearable></el-input>
            </el-form-item>
            <el-form-item label="手机:">
              <el-input size="small" v-model="searchForm.mobilePhoneNumber" placeholder="请输入手机" clearable></el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
            <el-button type="primary" size="small" @click="add">添加临时主体</el-button>
          </div>
          <p class="tips-red text-right">请录入查询信息，再点“查询”</p>

        </el-form>
      </div>
      <el-table 
        ref="table" 
        class="my-table min-dialog-height"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        height="calc(100vh - 387px)"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="choose(scope.row, scope.$index)">选择</el-button>
            <el-button v-if="scope.row.showUpdate" size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
      <temp-edit ref="editForm" @getData="getData" @addTemp="addTemp" :tempType="tempType"></temp-edit>
    </div>
  </el-dialog>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  import tempEdit from '@/views/common/tempEdit.vue'
  export default {
    name: 'condition',
    components: {
      pagination,
      tempEdit
    },
    props:{
      tempType: {
        type: String,
        default: '01'
      },
    },
    data() {
      let title = '选择主体'
      return {
        closeFlag: false,
        loading: false,
        title: '选择主体',
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          userName: utils.getUserName(),
          bodyName: '',
          creditCode: '',
          mobilePhoneNumber: '',
          areaCode: '',
          areaLeve: ''
        },
        urlName: 'commonbodypage',
        tableHead: [{
          prop: 'bodyName',
          label: '公司名称'
        },{
          prop: 'areaName',
          label: '所属区域'
        },{
          prop: 'creditCode',
          label: '统一信用代码或身份证',
          width: 180
        },{
          prop: 'mobilePhoneNumber',
          label: '手机',
          width: 180
        }],
        initForm: {
          bodyId: '',
          bodyType: '',
          productionBodyType: '',
          businessBodyType: '',
          bodyName: '',
          representativeName: '',
          creditCode: '',
          mobilePhoneNumber: '',
          businessAddress: '',
          linkman: '',
          linkmanPhoneNumber: '',
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          town: '',
          townCode: '',
          village: '',
          villageCode: '',
          address: '',
          retail: ''
        },
        loadtext: '加载中',
        pageName: '临时主体',
        tableName: `${title}列表`,
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      // this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      async initTable(){
        this.getData()
      },
      addTemp(data){
        this.getData()
        this.choose(data)
      },
      initData(){
        this.closeFlag = true
        this.$nextTick(() => {
          this.$refs.table &&  this.$refs.table.doLayout()
        })
      },
      choose(data){
        this.$emit('getcondition', data)
        this.closeModel()
      },
      async add(){
        // let id = await utils.getNewId(),
        let obj = {
          ...this.initForm
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.commonproductDetail(data.bodyId).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              address: utils.getAddressByObj(result, true)
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.bodyName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.commondeletesupplier([data.supplierId], _this.tempType == '01' ? '' : 'product-of-input').then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm, _this.tempType == '01' ? '' : 'product-of-input').then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                areaName: (item.province || '') + (item.city || '') + (item.county || '') + (item.town || '') + (item.village || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },
      closeModel() {
        this.closeFlag = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .text-right{
    text-align: right;
  }
</style>